export default {
    name: 'FlexiTab',
    props: ['label', 'initialImg', 'url'],

    data() {
        return {
            active: false,
        };
    },

    computed: {
        hasImg() {
            return ! Array.isArray(this.initialImg);
        },

        img() {
            if (this.hasImg) {
                return this.initialImg.path;
            }

            return `${window.App.url}/themes/frontend/public/images/image-placeholder.png`;
        },
    },

    template: '<div></div>',
};
