require('./vendors/vendors');

$(() => {
    let select = $('.custom-select-option');
	//todo uncomment
    // select.niceSelect();
    select.on('change', (e) => {
        e.target.dispatchEvent(new Event('nice-select-updated', { bubbles: true }));
    });


    $('.post_category ul li i').on('click', (e) => {
        $(e.currentTarget).toggleClass('icofont-simple-right icofont-simple-down').siblings('.dropdown-menu').slideToggle(300);
    });





    /*      image gallery
    /*----------------------------------------*/

    let baseImage = $('.product-picture');

    $('.more-images-div').slick({
        rows: 0,
        dots: false,
        arrows: true,
        vertical: true,
        infinite: false,
        slidesToShow: 4,
        slideToScroll: 1,
        asNavFor: baseImage,
        focusOnSelect: true,
        adaptiveHeight: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 577,
                settings: {
                    vertical: false,
                    variableWidth: true,
                    verticalSwiping: false,
                    rtl: window.App.rtl,
                },
            },
        ],
    });

    baseImage.slick({
        rows: 0,
        fade: true,
        dots: false,
        swipe: false,
        arrows: false,
        infinite: false,
        draggable: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: window.App.rtl,
    });

    baseImage.slickLightbox({
        src: 'data-image',
        itemSelector: '.product-picture-slide',
        slick: {
            fade: true,
            infinite: false,
            rtl: window.App.rtl,
        },
    });

    $('.product-picture-slide').zoom({
        magnify: 1.2,
        touch: false,
    });



});
