<template>
    <section class="landscape-products-wrap" v-if="hasAnyProduct">
        <div class="products-header">
            <h5 class="section-title">{{ $trans('frontend::product.related_products') }}</h5>
        </div>


          <div class="row partner-slider">

            <div class="col-md-12" v-for="(product, index) in products" :key="index">
              <div class="product-slider-item text-center">
                <div class="product-image">
                  <img class="mx-auto "
                       :src="product.picture.path"
                       alt="">
                </div>
                <h4 class="mt-2" style="min-height:35px;" v-html="product.name"></h4>
                <div class="price" style="min-height:45px;font-size: 15px;">Cena:
                  <span class="product-prev-price" v-if="product.has_percentage_discount">{{ product.discount_percent }}</span>
                  <span class="product-price" v-html="product.formatted_price"></span>
                </div>
                <a :href="productUrl" class="btn mb-1 mt-2">PREZRIEŤ<i class="icofont-bubble-right"></i></a>
              </div>
            </div>
          </div>


    </section>
</template>

<script>
    import ProductCard from './../../ProductCard.vue';
    import { slickPrevArrow, slickNextArrow } from '../../../functions';

    export default {
        components: { ProductCard },

        props: ['products'],

        computed: {
            hasAnyProduct() {
                return this.products.length !== 0;
            },
        },

        mounted() {
            $(this.$refs.productsPlaceholder).slick({
                rows: 0,
                dots: false,
                arrows: true,
                infinite: true,
                slidesToShow: 5,
                slidesToScroll: 5,
                rtl: window.App.rtl,
                prevArrow: slickPrevArrow(),
                nextArrow: slickNextArrow(),
                responsive: [
                    {
                        breakpoint: 1761,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 1341,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 1081,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 881,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 661,
                        settings: {
                            dots: true,
                            arrows: false,
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 641,
                        settings: {
                            dots: true,
                            arrows: false,
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                ],
            });
        },
    };
</script>
