export default {
	data() {
		return {
			tabs      : [],
			currentTab: null,
			loading   : false,
			products  : [],
		};
	},

	mounted() {
		this.tabs = this.$children.filter((component) => {
			return component.$options.name === 'FlexiTab';
		});
		this.change(this.tabs[0]);
	},

	methods: {
		classes(tab) {
			return {
				'tab-item': true,
				loading   : this.currentTab === tab && this.loading,
				active    : this.currentTab === tab && !this.loading,
			};
		},

		spinner(tab,position) {
			if (this.currentTab === tab && this.loading) {
				return "<div role=\"status\" class=\"spinner-border  spinner-border-sm\" style='position:absolute;top:12px;"+ position +":10px;'><span class=\"sr-only\">Loading...</span></div>";
			}
		},

		change(currentTab) {
			if (this.currentTab === currentTab) {
				return;
			}
			this.loading = true;
			this.currentTab = currentTab;
			$.ajax({
				method: 'GET',
				url   : currentTab.url,
			}).then((products) => {
				this.products = products;
				this.loading = false;
			});
		},
	},
};
