<template>
    <ul class="pagination">
        <li  :class="{ disabled: isFirstPage }">
            <button  :disabled="isFirstPage" @click="previous">
                <i class="las la-angle-left"></i>
            </button>
        </li>

        <li v-show="firstPageRange !== 1" class="page-item">
            <button  @click="toPage(1)">
                1
            </button>
        </li>

        <li v-show="firstPageRange === 3" class="page-item">
            <button @click="toPage(2)">
                2
            </button>
        </li>

        <li
            v-show="firstPageRange !== 1 && firstPageRange !== 2 && firstPageRange !== 3"
            class="disabled"
        >
            <span class="">...</span>
        </li>

        <li
            v-for="page in range"
            :key="page"
            :class="{ active: hasActive(page) }"
        >
            <button  @click="toPage(page)">
                {{ page }}
            </button>
        </li>

        <li
            v-show="lastPageRange !== total && lastPageRange !== (total - 1) && lastPageRange !== (total - 2)"
            class="disabled"
        >
            <span >...</span>
        </li>

        <li v-show="lastPageRange === (total - 2)" >
            <button  @click="toPage(total - 1)">
                {{ total - 1 }}
            </button>
        </li>

        <li v-if="lastPageRange !== total" >
            <button  @click="toPage(total)">
                {{ total }}
            </button>
        </li>

        <li  :class="{ disabled: isLastPage }">
            <button  :disabled="isLastPage" :class="{ disabled: isLastPage }" @click="nextPage">
                <i class="las la-angle-right"></i>
            </button>
        </li>
    </ul>
</template>

<script>
    export default {
        props: {
            total: Number,
            current: Number,
            maxRange: {
                type: Number,
                default: 3,
            },
        },

        mounted() {
            if (this.current > this.total) {
                this.$emit('page-switch', this.total);
            }
        },

        computed: {
            firstPageRange() {
                if (this.current === 1) {
                    return 1;
                }

                if (this.current === this.total) {
                    if ((this.total - this.maxRange) < 0) {
                        return 1;
                    }

                    return this.total - this.maxRange + 1;
                }

                return this.current - 1;
            },

            lastPageRange() {
                return Math.min(this.firstPageRange + this.maxRange - 1, this.total);
            },

            range() {
                let rangeList = [];

                for (let page = this.firstPageRange; page <= this.lastPageRange; page += 1) {
                    rangeList.push(page);
                }

                return rangeList;
            },

            isFirstPage() {
                return this.current === 1;
            },

            isLastPage() {
                return this.current === this.total;
            },
        },

        methods: {
            previous() {
                this.$emit('page-switch', this.current - 1);
            },

            nextPage() {
                this.$emit('page-switch', this.current + 1);
            },

            toPage(page) {
                if (this.current !== page) {
                    this.$emit('page-switch', page);
                }
            },

            hasActive(page) {
                return page === this.current;
            },
        },
    };
</script>


<style>
	.pagination > li {
		display: inline; }
	.pagination > li > button,
	.pagination > li > span {
		position: relative;
		float: left;
		padding: 10px 21px;
		line-height: 1.42857;
		text-decoration: none;
		color: #337ab7;
		background-color: #fff;
		border: 1px solid #f0f0f0;
		border-top: 2px solid #f0f0f0;
		border-bottom: 2px solid #f0f0f0;
		margin-left: -1px; }
	.pagination > li:first-child > button,
	.pagination > li:first-child > span {
		margin-left: 0;
		border-bottom-left-radius: 999px;
		border-top-left-radius: 999px;
		padding-left: 30px;
		border-left: 2px solid #f0f0f0; }
	.pagination > li:last-child > button,
	.pagination > li:last-child > span {
		border-bottom-right-radius: 999px;
		border-top-right-radius: 999px;
		padding-right: 30px;
		border-right: 2px solid #f0f0f0; }
	.pagination > li > button,
	.pagination > li > span {
		color: #999; }
	.pagination > .active > button,
	.pagination > .active > span {
		color: #333333; }
	.pagination > .disabled > span,
	.pagination > .disabled > span:hover,
	.pagination > .disabled > span:focus,
	.pagination > .disabled > button,
	.pagination > .disabled > button:hover,
	.pagination > .disabled > button:focus {
		color: #777777;
		background-color: #fff;
		cursor: not-allowed; }

	.pagination-lg > li > button,
	.pagination-lg > li > span {
		padding: 10px 16px;
		font-size: 18px;
		line-height: 1.33333; }
	.pagination-lg > li:first-child > button,
	.pagination-lg > li:first-child > span {
		border-bottom-left-radius: 6px;
		border-top-left-radius: 6px; }
	.pagination-lg > li:last-child > button,
	.pagination-lg > li:last-child > span {
		border-bottom-right-radius: 6px;
		border-top-right-radius: 6px; }

	.pagination-sm > li > button,
	.pagination-sm > li > span {
		padding: 5px 10px;
		font-size: 12px;
		line-height: 1.5; }
	.pagination-sm > li:first-child > button,
	.pagination-sm > li:first-child > span {
		border-bottom-left-radius: 3px;
		border-top-left-radius: 3px; }
	.pagination-sm > li:last-child > button,
	.pagination-sm > li:last-child > span {
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px; }

</style>