window.$ = window.jQuery = require('jquery');

require('popper.js');
require('bootstrap');
require('jquery-nice-select');
require('jquery-zoom');
require('slick-carousel');
require('slick-lightbox');
require('./slick-animation.js');
require('../../../../../node_modules/kbw-countdown/dist/js/jquery.plugin.js');
require('../../../../../node_modules/kbw-countdown/src/js/jquery.countdown.js');
