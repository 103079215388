<template>
        <ProductCard classname="col-6 col-sm-6 col-md-4 col-lg-4 product-item" :product="product"/>
</template>

<script>
    import ProductCard from './../../ProductCard.vue';

    export default {
        components: { ProductCard },

        props: ['product'],
    };
</script>
