<template>
	<div class="header-search" v-click-outside="skryNavrhy">

		<form method="get" class="searchform ajax-search" @submit.prevent="hladaj" role="search">

			<div class="search_ber">
				<input type="hidden" name="type" value="product">
				<input
						type="text"
						name="query"
						class="form-control search_button"
						autocomplete="off"
						v-model="form.query"
						:placeholder="placeholder"
						@keydown.esc="skryNavrhy"
				>
				<ul class="list-product-search hidden-xs hidden-sm">
					<li
							v-for="product in navrhy.products"
							:key="product.slug"
							class="list-item"
							:class="{ active: jeAktivny(product) }"
							:ref="product.slug"
							@mouseover="zmenAktivnyNavrh(product)"
							@mouseleave="zmazAktivnyNavrh"
					>
						<a :href="product.url" class="flex align-center" @click="skryNavrhy">
							<div class="product-img">
								<img
										:src="picture(product)"
										:class="{ 'image-placeholder': ! hasPicture(product) }"
										alt="product image"
								>
							</div>
							<h3 class="product-title" v-html="product.name"></h3>

						</a>
					</li>
				</ul>


<!--				<div class="search-panel">-->
<!--					<a class="dropdown-toggle" data-toggle="dropdown" href='#'>Všetko-->
<!--						<span class="fa fa-caret-down"></span>-->
<!--					</a>-->
					<!--			<ul id="category" class="dropdown-menu dropdown-category">-->
					<!--				<li><a href="#">TV & Video</a></li>-->
					<!--				<li><a href="#">Home Audio & Theater</a></li>-->
					<!--				<li><a href="#">Camera, Photo & Video</a></li>-->
					<!--				<li><a href="#">Cell Phones & Accessories</a></li>-->
					<!--				<li><a href="#">Headphones</a></li>-->
					<!--				<li><a href="#">Car Electronics</a></li>-->
					<!--				<li><a href="#">Electronics Showcase</a></li>-->
					<!--			</ul>-->
<!--				</div>-->
				<i class="icofont-search-1 pointer"  @click="hladaj" ></i>
			</div>
		</form>
	</div>
</template>

<script>
	import ProductHelpersMixin from '../../mixins/ProductHelpersMixin';

	export default {
		mixins: [
			ProductHelpersMixin,
		],

		props: ['kategorie', 'hladanyVyraz', 'prvaKategoria', 'placeholder'],

		data() {
			return {
				aktivnyNavrh: null,
				zobrazNavrhy: false,
				form        : {
					query   : this.hladanyVyraz,
					category: this.prvaKategoria,
				},
				navrhy      : {
					kategorie: [],
					products : [],
					remaining: 0,
				},
			};
		},

		computed: {
			prvaKategoriaNieVZoznane() {
				return !this.kategorie.includes(this.prvaKategoria);
			},

			trebaZobrazitNavrhy() {
				if (!this.zobrazNavrhy) {
					return false;
				}

				return this.nejakeNavrhy;
			},

			viacVysledkov() {
				if (this.form.category) {
					return route('categories.products.index', this.form);
				}

				return route('products.index', {query: this.form.query});
			},

			nejakeNavrhy() {
				return this.navrhy.products.length !== 0;
			},

			vsetkyNavrhy() {
				return [...this.navrhy.kategorie, ...this.navrhy.products];
			},

			prvyNavrh() {
				return this.vsetkyNavrhy[0];
			},

			poslednyNavrh() {
				return this.vsetkyNavrhy[this.vsetkyNavrhy.length - 1];
			},
		},

		watch: {
			'form.query': function (newQuery) {
				if (newQuery === '') {
					this.resetniNavrhy();
				} else {
					this.zobrazNavrhy = true;

					// this.dajNavrhy();
				}
			},
		},

		methods: {
			zmenKategoriu(category) {
				this.form.category = category;

				this.dajNavrhy();
			},

			dajNavrhy() {
				$.ajax({
					method: 'GET',
					url   : route('nasepkavac.get', this.form),
				}).then((navrhy) => {
					this.navrhy.kategorie = navrhy.kategorie;
					this.navrhy.products = navrhy.products;
					this.navrhy.remaining = navrhy.remaining;

					this.zmazAktivnyNavrh();
					this.resetniBar();
				});
			},

			hladaj() {
				if (!this.form.query) {
					return;
				}

				if (this.aktivnyNavrh) {
					window.location.href = this.aktivnyNavrh.url;

					this.skryNavrhy();

					return;
				}

				if (this.form.category) {
					window.location.href = route('categories.products.index', this.form);

					return;
				}

				window.location.href = route('products.index', {query: this.form.query});
			},

			resetniNavrhy() {
				this.navrhy.kategorie = [];
				this.navrhy.products = [];
			},

			skryNavrhy(e) {
				this.zobrazNavrhy = false;

				this.zmazAktivnyNavrh();
			},

			jeAktivny(nasepkavac) {
				if (!this.aktivnyNavrh) {
					return false;
				}

				return this.aktivnyNavrh.slug === nasepkavac.slug;
			},

			zmenAktivnyNavrh(nasepkavac) {
				this.aktivnyNavrh = nasepkavac;
			},

			zmazAktivnyNavrh() {
				this.aktivnyNavrh = null;
			},

			dalsiNavrh() {
				if (!this.nejakeNavrhy) {
					return;
				}

				this.aktivnyNavrh = this.vsetkyNavrhy[this.dalsiNavrhPoradie()];

				if (!this.aktivnyNavrh) {
					this.aktivnyNavrh = this.prvyNavrh;
				}

				this.upravBar();
			},

			spatnyNavrh() {
				if (!this.nejakeNavrhy) {
					return;
				}

				if (this.spatnyNavrhPoradie() === -1) {
					this.zmazAktivnyNavrh();

					return;
				}

				this.aktivnyNavrh = this.vsetkyNavrhy[this.spatnyNavrhPoradie()];

				if (!this.aktivnyNavrh) {
					this.aktivnyNavrh = this.poslednyNavrh;
				}

				this.upravBar();
			},

			dalsiNavrhPoradie() {
				return this.aktualnyNavrhPoradie() + 1;
			},

			spatnyNavrhPoradie() {
				return this.aktualnyNavrhPoradie() - 1;
			},

			aktualnyNavrhPoradie() {
				return this.vsetkyNavrhy.indexOf(this.aktivnyNavrh);
			},

			upravBar() {
				this.$refs.navrhyBody.scrollTop = this.$refs[this.aktivnyNavrh.slug][0].offsetTop - 200;
			},

			resetniBar() {
				this.$refs.navrhyBody.scrollTop = 0;
			},
		},
	};
</script>
