<template>
    <div class="product-rating">
        <div class="back-stars">
            <i class="las la-star"></i>
            <i class="las la-star"></i>
            <i class="las la-star"></i>
            <i class="las la-star"></i>
            <i class="las la-star"></i>

            <div class="front-stars" :style="{ width: percentValue + '%' }">
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
                <i class="las la-star"></i>
            </div>
        </div>

        <span class="rating-count" v-if="hasCountOfReviews">({{ this.countOfReviews }})</span>
    </div>
</template>

<script>
    export default {
        props: ['percentValue', 'countOfReviews'],

        computed: {
            hasCountOfReviews() {
                return this.countOfReviews !== undefined;
            },
        },
    };
</script>
