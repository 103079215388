import store from '../store';

export default {
    data() {
        return {
            cartAddLoading: false,
        };
    },

    computed: {
        productUrl() {
            return route('products.show', this.product.slug);
        },

        hasOption() {
            return this.product.options_count > 0;
        },

        noOptions() {
            return ! this.hasOption;
        },

        hasPicture() {
            return this.product.picture.length !== 0;
        },

        picture() {
            if (this.hasPicture) {
                return this.product.picture.path;
            }

            return `${window.App.url}/themes/frontend/public/images/image-placeholder.png`;
        },

        inWishList() {
            return store.inWishList(this.product.id);
        },

        inCompareList() {
            return store.inCompareList(this.product.id);
        },
    },

    methods: {
	    quickView() {
		    store.quickView(this.product);
	    },

        updateWishList() {
            store.updateWishList(this.product.id);
        },

        updateCompareList() {
            store.updateCompareList(this.product.id);
        },

        addToBasket() {
            this.cartAddLoading = true;

            $.ajax({
                method: 'POST',
                url: route('cart.items.store', { product_id: this.product.id, qty: 1 }),
            }).then((cart) => {
                store.updateBasket(cart);
                if (document.location.href !== route('cart.index').url()) {
                    $('.header-cart').trigger('click');
                }
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.cartAddLoading = false;
            });
        },
    },
};
