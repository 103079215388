export default {
    methods: {
        productUrl(product) {
            return route('products.show', product.slug);
        },

        hasPicture(product) {
            return product.picture.length !== 0;
        },

        picture(product) {
            if (this.hasPicture(product)) {
                return product.picture.path;
            }

            return `${window.App.url}/themes/frontend/public/images/image-placeholder.png`;
        },
    },
};
