<template>
	<div v-if="product" class="quickview-wrapper open">
		<div class="quick-modal show">
			<div id="quickview-content">
				<a @click="quickView" class="close-btn quickview-close pointer"></a>
				<div class="single-product-detail">
					<div class="row">
						<div class="col-md-6 col-sm-6">
							<div class="product-img js-quickview-slide">
								<div class="main-img" v-for="picture in product.files">
									<a class="hover-images effect" :href="productUrl">
										<img :src="picture.path" alt="" class="img-reponsive"></a>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-sm-6">
							<div class="single-flex">
								<div class="single-product-info product-info product-grid-v2 s-50">
									<p class="product-cate">Audio Speakers</p>
									<product-rating :rating-percent="product.rating_percent"
													:review-count="0"></product-rating>

									<h3 class="product-title"><a :href="productUrl">{{ product.name }}</a></h3>
									<div class="product-price">
										<span v-html="product.formatted_price"></span>
									</div>
									<div class="product-sku">
										<label>SKU :</label> <span v-text="product.sku"> </span>
									</div>
									<div class="short-desc">
										<p class="product-desc" v-html="product.short_description"></p>
									</div>

									<div class="short-desc">
										<p class="product-desc" v-html="product.description"></p>
									</div>

									<div class="single-product-button-group">
										<div class="e-btn cart-qtt btn-gradient" @click="addToBasket"
											 v-if="product.in_stock">
											<a class="btn-add-cart">Add to cart <span
													class="icon-bg icon-cart v2"></span></a>
										</div>

										<span v-else class="badge badge-warning" style="margin-right: 10px">
											{{ $trans('outOfStock') }}
										</span>


										<a class="btn-icon pointer" @click="updateWishList">
											<i style="font-size:23px;" class="la-heart"
											   :class="inWishList ? 'las' : 'lar'"></i>
										</a>

										<!--										<a href="#" class="e-btn btn-icon">-->
										<!--											<span class="icon-bg icon-compare"></span>-->
										<!--										</a>-->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import ProductCardMixin from "../mixins/ProductCardMixin";
	import ProductRating from "./ProductRating";

	export default {

		props: ['product'],

		mounted() {
			var t = this;

			$('.quickview-wrapper.open').click((event) => {
				if (!$(event.target).closest('.quick-modal').length) {
					t.quickView();
				}
			});

			$('.js-quickview-slide').slick({
				autoplay      : false,
				slidesToShow  : 1,
				slidesToScroll: 1,
				infinite      : true,
				arrows        : true,
				prevArrow     : '<span class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
				nextArrow     : '<span class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
				dots          : true
			});
		},

		components: {ProductRating},
		mixins    : [
			ProductCardMixin,
		],

	};


</script>


<style lang="scss" scoped>
	.e-btn.cart-qtt.btn-gradient {
		width: 150px;
		cursor: pointer;

		a {
			margin-left: 16px;
		}
	}
</style>
