import store from '../../store';
import CartHelpersMixin from '../../mixins/CartHelpersMixin';
import ProductHelpersMixin from '../../mixins/ProductHelpersMixin';

export default {
    mixins: [
        CartHelpersMixin,
        ProductHelpersMixin,
    ],

    data() {
        return {
            shippingMethodTitle: null,
            csProducts: [],
        };
    },

    computed: {
        hasAnyCrossSellProduct() {
            return this.csProducts.length !== 0;
        },
    },

    created() {
        this.$nextTick(() => {
            if (store.state.basket.shippingMethodName) {
                this.changeShippingMethod(store.state.basket.shippingMethodName);
            } else {
                this.updateShipping(this.defaultShipping);
            }

            this.loadCSProducts();
        });
    },

    methods: {
        getProductOptions(option) {
            let values = [];

            for (let value of option.values) {
                values.push(value.label);
            }

            return values.join(', ');
        },

        refreshQuantity(cartItem, qty) {
            if (qty < 1 || this.stockExceed(cartItem, qty)) {
                return;
            }

            if (isNaN(qty)) {
                qty = 1;
            }

            this.orderIsLoading = true;

            cartItem.qty = qty;

            $.ajax({
                method: 'PUT',
                url: route('cart.items.update', { cartItemId: cartItem.id }),
                data: { qty: qty || 1 },
            }).then((cart) => {
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.orderIsLoading = false;
            });
        },

        stockExceed(cartItem, qty) {
            return cartItem.product.manage_stock && cartItem.product.qty < qty;
        },

        remove(cartItem) {
            this.orderIsLoading = true;

            store.removeBasketItem(cartItem);

            if (store.basketIsEmpty()) {
                this.csProducts = [];
            }

            $.ajax({
                method: 'DELETE',
                url: route('cart.items.destroy', { cartItemId: cartItem.id }),
            }).then((cart) => {
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.orderIsLoading = false;
            });
        },

        emptyBasket() {
            store.emptyBasket();

            if (store.basketIsEmpty()) {
                this.csProducts = [];
            }

            $.ajax({
                method: 'POST',
                url: route('cart.clear.store'),
            }).then((cart) => {
                store.updateBasket(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            });
        },

        changeShippingMethod(shippingMethod) {
            this.shippingMethodTitle = shippingMethod.name;
        },

        loadCSProducts() {
            $.ajax({
                method: 'GET',
                url: route('cart.cross_sell_products.index'),
            }).then((csProducts) => {
                this.csProducts = csProducts;
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            });
        },
    },
};
