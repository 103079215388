<template>
	<div class="row mt-5">
		<ul class="nav nav-tabs nav_custom" id="myTab" role="tablist">
			<li
					v-for="(tab, index) in tabs"
					:key="index"
					class="nav-item"
					:class="classes(tab)"
					@click="change(tab)"
			>
				<a class="pointer nav-link nav-link-custom " :class="classes(tab)">

					<span v-html="spinner(tab,'left')"></span>
					{{ tab.label }}

				</a>
			</li>
		</ul>


		<ProductCard
				v-for="product in products"
				:key="product.id"
				:product="product"
				classname="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4"/>


	<flexi-tab
			v-for="(tabName, index) in data"
			:key="index"
			:label="tabName"
			:url="route('frontend.product_tabs.index', { sectionId: 1, tabId: index + 1 })"
	>
	</flexi-tab>
	</div>

</template>

<script>
	import ProductCard from '../ProductCard.vue';
	import FlexiTabMixin from '../../mixins/FlexiTabMixin';

	export default {
		components: {ProductCard},

		mixins: [
			FlexiTabMixin,
		],
		props : ['data'],
	};
</script>
