import noUiSlider from 'nouislider';
import { trans } from '../../functions';
import { unrollFilters } from './index/helpers';

export default {
    props: [
        'defaultQuery',
        'defaultBrand',
        'defaultBrandPicture',
        'defaultBrandSlug',
        'defaultCategoryTitle',
        'defaultCategoryItems',
        'defaultCats',
        'defaultCategoryPicture',
        'defaultCategorySlug',
        'defaultTagTitle',
        'defaultTagSlug',
        'defaultParameter',
        'maxPrice',
        'defaultSort',
        'defaultPerPage',
        'defaultPage',
        'defaultView',
        'defaultBikeModel',
    ],

    data() {
        return {
            productsLoading: false,
            products: { data: [] },
            parameterFilters: [],
            brandImage: this.defaultBrandPicture,
            categoryItems: this.defaultCategoryItems,
            cats: this.defaultCats,
            categoryTitle: this.defaultCategoryTitle,
            categoryImage: this.defaultCategoryPicture,
            view: this.defaultView,
            queryParameters: {
                query: this.defaultQuery,
                brand: this.defaultBrandSlug,
                bikemodel: this.defaultBikeModel,
                category: this.defaultCategorySlug,
                tag: this.defaultTagSlug,
                attribute: this.defaultParameter,
                fromPrice: 0,
                toPrice: this.maxPrice,
                sort: this.defaultSort,
                perPage: this.defaultPerPage,
                page: this.defaultPage,
            },
        };
    },

    computed: {
        noProducts() {
            return this.products.data.length === 0;
        },

        totalPage() {
            return Math.ceil(this.products.total / this.queryParameters.perPage);
        },

        resultsFounded() {
            if (this.noProducts) {
                return;
            }

            return trans('results', {
                from: this.products.from,
                to: this.products.to,
                total: this.products.total,
            });
        },
    },

    mounted() {
        this.setEventListeners();
        this.priceFilterLoad();
        this.loadProducts();
    },

    methods: {
        setEventListeners() {
            $(this.$refs.sortSelect).on('change', (e) => {
                this.queryParameters.sort = e.currentTarget.value;

                this.loadProducts();
            });

            $(this.$refs.perPageSelect).on('change', (e) => {
                this.queryParameters.perPage = e.currentTarget.value;

                this.loadProducts();
            });
        },

        priceFilterLoad() {
	            noUiSlider.create(this.$refs.priceRange, {
	                connect: true,
	                direction: window.App.rtl ? 'rtl' : 'ltr',
	                start: [0, this.maxPrice],
	                range: {
	                    min: [0],
	                    max: [this.maxPrice],
	                },
	            });

            this.$refs.priceRange.noUiSlider.on('update', (values, handle) => {
                let value = Math.round(values[handle]);

                if (handle === 0) {
                    this.queryParameters.fromPrice = value;
                } else {
                    this.queryParameters.toPrice = value;
                }
            });

            this.$refs.priceRange.noUiSlider.on('change', this.loadProducts);
        },

        changePriceRange(fromPrice, toPrice) {
            this.$refs.priceRange.noUiSlider.set([fromPrice, toPrice]);

            this.loadProducts();
        },

        switchParameterFilter(slug, value) {
            if (! this.queryParameters.attribute.hasOwnProperty(slug)) {
                this.queryParameters.attribute[slug] = [];
            }

            if (this.queryParameters.attribute[slug].includes(value)) {
                this.queryParameters.attribute[slug].splice(
                    this.queryParameters.attribute[slug].indexOf(value),
                    1
                );
            } else {
                this.queryParameters.attribute[slug].push(value);
            }

            this.loadProducts({ updateparameterFilters: false });
        },

        switchAttributeFilter(slug, value) {
            this.queryParameters.attribute = {};
            this.queryParameters.category = null;
            this.queryParameters.attribute[slug] = value;
            this.loadProducts({ updateparameterFilters: true });
        },

        isFilteredByAttribute(slug, value) {
            if (! this.queryParameters.attribute.hasOwnProperty(slug)) {
                return false;
            }

            return this.queryParameters.attribute[slug].includes(value);
        },

        changeCategory(category) {
            this.categoryTitle = category.name;
            this.categoryItems = category.items;
            this.queryParameters.category = category.slug;
            this.queryParameters.query = null;
            this.queryParameters.attribute = {};
            this.queryParameters.page = 1;
            this.loadProducts();
            // this.categoryImage = category.banner.path;
        },

        switchPage(page) {
            this.queryParameters.page = page;

            this.loadProducts();
        },

        loadProducts(options = { updateparameterFilters: true }) {
            this.productsLoading = true;

            $.ajax({
                url: route('products.index', this.queryParameters),
            }).then((response) => {
                this.products = response.products;

                if (options.updateparameterFilters) {
                    this.parameterFilters = response.attributes;
                }

                this.$nextTick(() => {
                    unrollFilters();
                });
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.productsLoading = false;
            });
        },
    },
};
