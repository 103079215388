<template>
  <div :class="classname ? classname : 'product-item'">

    <div class="product-slider-item ">
      <div class="product-image ">
        <img :src="picture"
                                      alt="" class="mx-auto "></div>
      <h4 class="mt-2" style="min-height: 15px;">
        <a :href="productUrl" class="product-name mb-1 mt-2" tabindex="-1">{{ product.name }}</a></h4>
      <div class="price" style="min-height: 45px; font-size: 15px;">
        <span class="product-prev-price" v-if="product.has_percentage_discount">{{ product.discount_percent }}</span>
        <span class="product-price" v-html="product.formatted_price"></span>
        <span class="product-price" style="font-size: 12px;color:#333;" v-if="locale=='hu'" v-html="'('+product.hu_price+')'"></span>

        <span class="sold-out-tag position-top-right" v-if="!product.in_stock">{{ $trans('outOfStock') }}</span>

      </div>


      <a class="add-to-cart-btn btn mb-1 mt-2"
         :class="{ 'btn-loading': cartAddLoading }"
         tabindex="0"
         @click="addToBasket"
         :disabled="product.is_out_of_stock"
         v-if="noOptions || product.is_out_of_stock">{{ $trans('buy') }}
      </a>

      <a  class="btn mb-1 mt-2"
         :class="{ 'btn-loading': cartAddLoading }"
         tabindex="0"
         @click="addToBasket"
         :href="productUrl"
         v-else>
        {{ $trans('showOptions') }}
      </a>

    </div>


<!--    <div class="product">-->
<!--      <div class="product-img">-->
<!--        <img class="w-100" :src="picture" alt="">-->
<!--      </div>-->
<!--      <div class="product-content">-->
<!--        <div class="product-details position-bottom-left">-->
<!--          <h3 class="product-name"><a :href="productUrl">{{ product.name }}</a></h3>-->
<!--          <span class="product-prev-price" v-if="product.has_percentage_discount">{{ product.discount_percent }}</span>-->
<!--          <span class="product-price" v-html="product.formatted_price"></span>-->
<!--        </div>-->
<!--        <div class="buttons">-->
<!--          <span class="sold-out-tag position-top-right" v-if="!product.in_stock">{{ $trans('outOfStock') }}</span>-->

<!--          <button class="btn custom-btn position-bottom-right"-->
<!--                  :class="{ 'btn-loading': cartAddLoading }"-->
<!--                  @click="addToBasket"-->
<!--                  :disabled="product.is_out_of_stock"-->
<!--                  v-if="noOptions || product.is_out_of_stock">{{ $trans('buy') }}-->
<!--          </button>-->
<!--          <a-->
<!--              v-else-->
<!--              :href="productUrl"-->
<!--              class="btn custom-btn position-bottom-right"-->
<!--          >-->
<!--            <i class="las la-eye"></i>-->
<!--            {{ $trans('showOptions') }}-->
<!--          </a>-->
<!--        </div>-->

<!--        <div class="icons position-center">-->
<!--          <div class="rounded-icon">-->
<!--            <a :href="productUrl">-->
<!--              <i class="icofont-eye"></i>-->
<!--            </a>-->
<!--          </div>-->

<!--          <div class="rounded-icon" @click="updateWishList">-->
<!--            <i class="icofont-heart" :class="inWishList ? '' : 'outline'"></i>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

  </div>
</template>

<script>
import ProductRating from './ProductRating.vue';
import ProductCardMixin from '../mixins/ProductCardMixin';

export default {

  components: {ProductRating},

  computed: {

    locale() {
      return window.App.locale;
    }

  },

  mixins: [
    ProductCardMixin,
  ],

  props: ['product', 'classname'],
};
</script>

<style>
.float-left {
  float: left;
}

.la-heart {
  color: #9e9e9e;
}

.la-heart.las {
  color: red;
}

</style>
