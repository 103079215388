import Vue from 'vue';
import {isEmpty} from './functions';

export default {
	state: Vue.observable({
		basket            : App.basket,
		form            : App.form,
		favourites        : App.favourites,
		quickViewProduct: false,
	}),

	basketIsEmpty() {
		return isEmpty(this.state.basket.items);
	},

	updateBasket(cart) {
		this.state.basket = cart;
	},

	removeBasketItem(cartItem) {
		Vue.delete(this.state.basket.items, cartItem.id);
	},

	emptyBasket() {
		this.state.basket.items = {};
	},

	hasShipping() {
		return Object.keys(this.state.basket.shippingMethodOptions).length !== 0;
	},

	wishlistCount() {
		return this.state.favourites.length;
	},

	inWishList(productId) {
		return this.state.favourites.includes(productId);
	},

	quickView(product) {
		if (product.id == this.state.quickViewProduct.id) {
			this.state.quickViewProduct = false;
		} else {
			this.state.quickViewProduct = product;
		}
	},

	updateWishList(productId) {
		if (this.inWishList(productId)) {
			this.removeFromWishlist(productId);
		} else {
			this.addToWishlist(productId);
		}
	},

	addToWishlist(productId) {
		if (App.auth) {
			this.state.favourites.push(productId);
		} else {
			return window.location.href = route('login');
		}

		$.ajax({
			method: 'POST',
			url   : route('wishlist.store'),
			data  : {productId},
		});
	},

	removeFromWishlist(productId) {
		this.state.favourites.splice(this.state.favourites.indexOf(productId), 1);

		$.ajax({
			method: 'DELETE',
			url   : route('wishlist.destroy', {productId}),
		});
	},

	inCompareList(productId) {
		return this.state.compareList.includes(productId);
	},

	updateCompareList(productId) {
		if (this.inCompareList(productId)) {
			this.removeFromCompareList(productId);
		} else {
			this.addToCompareList(productId);
		}
	},

	addToCompareList(productId) {
		this.state.compareList.push(productId);

		$.ajax({
			method: 'POST',
			url   : route('compare.store'),
			data  : {productId},
		});
	},

	removeFromCompareList(productId) {
		this.state.compareList.splice(this.state.compareList.indexOf(productId), 1);

		$.ajax({
			method: 'DELETE',
			url   : route('compare.destroy', {productId}),
		});
	},
};
